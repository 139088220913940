exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-archive-year-js": () => import("./../../../src/pages/archive/year.js" /* webpackChunkName: "component---src-pages-archive-year-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-crypto-kyc-js": () => import("./../../../src/pages/crypto-kyc.js" /* webpackChunkName: "component---src-pages-crypto-kyc-js" */),
  "component---src-pages-eventform-jsx": () => import("./../../../src/pages/eventform.jsx" /* webpackChunkName: "component---src-pages-eventform-jsx" */),
  "component---src-pages-kyb-js": () => import("./../../../src/pages/kyb.js" /* webpackChunkName: "component---src-pages-kyb-js" */),
  "component---src-pages-kyc-js": () => import("./../../../src/pages/kyc.js" /* webpackChunkName: "component---src-pages-kyc-js" */),
  "component---src-pages-major-sanctioned-countries-lists-js": () => import("./../../../src/pages/major-sanctioned-countries-lists.js" /* webpackChunkName: "component---src-pages-major-sanctioned-countries-lists-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-node-sale-kyc-js": () => import("./../../../src/pages/node-sale-kyc.js" /* webpackChunkName: "component---src-pages-node-sale-kyc-js" */),
  "component---src-pages-onchainkyc-js": () => import("./../../../src/pages/onchainkyc.js" /* webpackChunkName: "component---src-pages-onchainkyc-js" */),
  "component---src-pages-outsourced-kyc-aml-js": () => import("./../../../src/pages/outsourced-kyc-aml.js" /* webpackChunkName: "component---src-pages-outsourced-kyc-aml-js" */),
  "component---src-pages-pass-verify-js": () => import("./../../../src/pages/pass-verify.js" /* webpackChunkName: "component---src-pages-pass-verify-js" */),
  "component---src-pages-passclub-js": () => import("./../../../src/pages/passclub.js" /* webpackChunkName: "component---src-pages-passclub-js" */),
  "component---src-pages-real-estate-index-jsx": () => import("./../../../src/pages/real-estate/index.jsx" /* webpackChunkName: "component---src-pages-real-estate-index-jsx" */),
  "component---src-pages-starter-js": () => import("./../../../src/pages/starter.js" /* webpackChunkName: "component---src-pages-starter-js" */),
  "component---src-pages-sto-sto-slider-jsx": () => import("./../../../src/pages/sto/STOSlider.jsx" /* webpackChunkName: "component---src-pages-sto-sto-slider-jsx" */),
  "component---src-pages-the-kyb-registry-js": () => import("./../../../src/pages/the-kyb-registry.js" /* webpackChunkName: "component---src-pages-the-kyb-registry-js" */),
  "component---src-pages-unhostedwalletkyc-js": () => import("./../../../src/pages/unhostedwalletkyc.js" /* webpackChunkName: "component---src-pages-unhostedwalletkyc-js" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-industry-content-jsx": () => import("./../../../src/templates/industry-content.jsx" /* webpackChunkName: "component---src-templates-industry-content-jsx" */),
  "component---src-templates-legal-jsx": () => import("./../../../src/templates/legal.jsx" /* webpackChunkName: "component---src-templates-legal-jsx" */),
  "component---src-templates-month-archive-jsx": () => import("./../../../src/templates/month-archive.jsx" /* webpackChunkName: "component---src-templates-month-archive-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-postv-2-jsx": () => import("./../../../src/templates/postv2.jsx" /* webpackChunkName: "component---src-templates-postv-2-jsx" */)
}

